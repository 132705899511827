<template>
    <v-btn @click="goBack" icon class="ml-2" style="background-color: white !important;">
      <v-icon color="primary">fas fa-arrow-left</v-icon>
    </v-btn>
</template>

<script>
export default {
  name: "TopBarButtonBack",
  methods: {
    goBack() {
      //this.$router.push({name: "Itinerary"});
      if (['Itinerary', 'PointDetails'].includes(this.$route.name))
        this.$router.replace({name: "Home"});
      else
        this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>